import {FormField} from "../../../api/form/model";
import CompleteButton from "../complete-button/complete-button";
import './complete-button-list.scss'
import {Task} from "../../../api/task/model";

interface CompleteButtonListProps {
    readonly: boolean;
    task: Task
    fields: FormField[],
    onChange: (field: FormField, value: any) => void;
}

export default function CompleteButtonList({task, fields, readonly, onChange}: CompleteButtonListProps) {
    return <div className="uk-grid">
        {fields.map((field) =>
            <div key={field.identifier} className="uk-width-1-1 uk-width-1-3@m uk-child-width-expand">
                <CompleteButton task={task} field={field} readonly={readonly} onChange={onChange}/>
            </div>
        )}
    </div>
}
