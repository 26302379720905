import classNames from "classnames";
import {FormInputProps} from "../form-input";


interface CompleteButtonProps extends FormInputProps {

}

export default function CompleteButton({field, readonly, onChange}: CompleteButtonProps) {
    const flavor = field.properties.completionFlavor;
    return <>
        {
            readonly
                ? <></>
                : <button
                    type="button"
                    onClick={() => onChange(field, true)}
                    className={classNames({
                        'uk-button': true,
                        'uk-button-primary': flavor === undefined || flavor === 'primary',
                        'uk-button-danger': flavor === 'danger'
                    })}>{field.label}</button>
        }
    </>
}