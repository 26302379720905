import {useQueryClient} from "react-query";
import {useDeleteMutation, usePostMutation} from "../mutation-queries";
import {CreateProcess, Process} from "./model";
import ServerStateKeysEnum from "../../react-query/ServerStateKeysEnum";


export enum ProcessMutations {
    Start = 'processStart',
    Delete = 'processDelete'
}

export function useStartProcess() {
    const queryClient = useQueryClient();
    return usePostMutation<CreateProcess, Process>(ProcessMutations.Start, {
        onSuccess: () => {
            queryClient.invalidateQueries([ServerStateKeysEnum.Tasks])
        }
    })
}

export function useDeleteProcess() {
    const queryClient = useQueryClient()
    return useDeleteMutation(ProcessMutations.Delete, {
        onSuccess: () => {
            queryClient.invalidateQueries([ServerStateKeysEnum.Tasks])
        }
    })
}