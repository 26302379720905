import './dynamic-form.scss'
import {useState} from "react";
import {Form, FormField} from "../api/form/model";
import FormInput from "./inputs/form-input";
import CompleteButtonList from "./inputs/complete-button-list/complete-button-list";
import {Task} from "../api/task/model";

interface DynamicFormProps {
    task: Task
    form: Form,
    readonly: boolean,
    data: any | undefined,
    onComplete?: (data: any) => void;
}

export default function DynamicForm({task, form, readonly, data, onComplete}: DynamicFormProps) {
    const existingCompleteButtons = form.fields
        .filter(field => field.properties.inputType === 'completeButton')

    const [currentData, setCurrentData] = useState<any | undefined>(data);


    const allFields = [
        ...form.fields,
        ...(existingCompleteButtons.length === 0 ? [{
            identifier: 'sys_completion',
            type: 'boolean',
            properties: {
                inputType: 'completeButton',
            },
            label: 'Abschließen'
        } as FormField] : [])
    ];

    const fieldsWithoutCompleteButtons = allFields
        .filter(f => f.properties.inputType !== 'completeButton');

    const completeButtons = allFields
        .filter(f => f.properties.inputType === 'completeButton');

    const onFormFieldChange = (field: FormField, value: any) => {
        if (field.properties.inputType === 'completeButton') {
            const newData = {...currentData}
            completeButtons.forEach((f) => { newData[f.identifier] = false });
            newData[field.identifier] = value;
            if(onComplete) {
                onComplete(newData);
            }
            setCurrentData(newData)
            return;
        }

        setCurrentData((d: any) => {
            const newData = {...d}
            newData[field.identifier] = value;
            return newData
        })

    }

    return <>
        <form className="uk-form-stacked">
            {
                fieldsWithoutCompleteButtons.map(
                    (f) => <FormInput
                        task={task}
                        key={f.identifier}
                        field={f}
                        readonly={readonly}
                        onChange={onFormFieldChange}
                    />
                )
            }
            <div className="uk-container uk-margin-left uk-margin-top" />

        </form>
        <CompleteButtonList task={task} fields={completeButtons} readonly={readonly} onChange={onFormFieldChange}/>
    </>;

}