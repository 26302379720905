import {UseQueryOptions} from "react-query";
import {useGetQuery} from "../../react-query/GetQuery";
import {Resource} from "./model";
import ServerStateKeysEnum from "../../react-query/ServerStateKeysEnum";
import {FetchError} from "../../react-query/CustomFetch";

export function useSearchResource(
    kind: string,
    query: string,
    options?: UseQueryOptions<Resource[], FetchError>
) {
    return useGetQuery<Resource[]>(
        [ServerStateKeysEnum.Resources, kind, query],
        `/api/${kind}?${query}`,
        options
    )
}

export function useGetResource(
    kind: string,
    id: string,
    options?: UseQueryOptions<Resource, FetchError>
) {
    return useGetQuery<Resource>(
        [ServerStateKeysEnum.Resources, kind, id],
        `/api/${kind}/${id}`,
        options
    )
}