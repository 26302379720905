import {useQueryClient, UseQueryOptions} from "react-query";
import {useGetQuery} from "../../react-query/GetQuery";
import ServerStateKeysEnum from "../../react-query/ServerStateKeysEnum";
import {Task, TaskCompletion} from "./model";
import {FetchError} from "../../react-query/CustomFetch";
import {usePostMutation} from "../mutation-queries";

const url = `/api/task`

export enum TaskMutations {
    Complete = 'taskComplete'
}

export function useGetTasks(options?: UseQueryOptions<Task[], FetchError>) {
    return useGetQuery<Task[]>([ServerStateKeysEnum.Tasks], url, options)
}

export function useCompleteTask() {
    const queryClient = useQueryClient()
    return usePostMutation<TaskCompletion, void>(TaskMutations.Complete, {
        onSuccess: () => {
            queryClient.invalidateQueries([ServerStateKeysEnum.Tasks])
        }
    });
}