import {useAuth} from "react-oidc-context";

const jsonContentType = 'application/json';
const jsonCharsetContentType = `${jsonContentType}; charset=utf-8`;
const jsonHateoasContentType = 'application/hal+json';

function useDefaultHeaders() {
    const {user}  = useAuth();
    const headers = new Headers();
    headers.set('Content-Type', jsonContentType);
    headers.set('Accept', jsonContentType);
    if (user?.access_token) {
        headers.set('Authorization', `Bearer ${user.access_token}`);
    }
    return headers;
}

export class FetchError extends Error {
    constructor(message: string, public isNetworkError: boolean, public status?: number, public statusText?: string) {
        super(message);
    }

    isUnauthorizedError(): boolean {
        return this.status === 401;
    }

    toString() {
        return `${this.status} ${this.statusText}`;
    }
}

const hasJsonBody = (response: Response) => {
    const contentType = response.headers.get('Content-Type');
    return (
        contentType != null && [jsonContentType, jsonHateoasContentType, jsonCharsetContentType].indexOf(contentType) !== -1
    );
};

export default function useCustomFetch() {
    const headers = useDefaultHeaders();

    return async function customFetch(path: string, init?: RequestInit) {
        let response: Response;
        try {
            response = await fetch(path, {
                headers,
                ...init,
            });
        } catch (err) {
            throw new FetchError(`Failed to make request`, true);
        }
        if (!response.ok) {
            throw new FetchError(
                `Network response was ${response.status} ${response.statusText}`,
                false,
                response.status,
                response.statusText
            );
        }
        if (hasJsonBody(response)) {
            return response.json();
        }
        return response;
    };
}
