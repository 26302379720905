import {useGetQuery} from "../../react-query/GetQuery";
import {Form} from "./model";
import ServerStateKeysEnum from "../../react-query/ServerStateKeysEnum";


export function useGetTaskForm(taskIdentifier: string) {
    return useGetQuery<Form>(
        [ServerStateKeysEnum.Tasks, taskIdentifier, ServerStateKeysEnum.Form],
        `/api/task/${taskIdentifier}/form`
    )
}