import './loading-spinner.scss'
import {ReactNode} from "react";


interface LoadingSpinnerProps {
    isLoading: boolean,
    children: ReactNode
}

export function LoadingSpinner({
                                   isLoading,
                                   children
                               }: LoadingSpinnerProps) {

    return isLoading
        ? <div className="loading-spinner">
            <div className="indicator" />
        </div>
        : <>{children}</>
}