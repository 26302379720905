import {Task} from "../../api/task/model";
import {useGetTaskForm} from "../../api/form/api";
import DynamicForm from "../../dynamic-form/dynamic-form";
import {useCompleteTask} from "../../api/task/api";
import {MutationPath} from "../../api/mutation-path";
import {LoadingSpinner} from "../../components/loading-spinner/loading-spinner";
import {useDeleteProcess} from "../../api/process/api";

interface TaskItemProps {
    task: Task
}

export default function TaskItem({task}: TaskItemProps) {
    const {data: formData, isLoading} = useGetTaskForm(task.identifier)

    const {mutate: completeTask} = useCompleteTask()
    const handleCompletion = (data: any) => {
        completeTask({
            path: MutationPath.CompleteTask(task.identifier),
            body: {
                answers: data
            }
        })
    }

    const {mutate: deleteProcess, isLoading: isDeleting} = useDeleteProcess()
    const handleDeleteProcess = () => {
        deleteProcess({
            path: MutationPath.DeleteProcess(task.process)
        })
    }

    return <div className="uk-card uk-card-default uk-card-body">
        <LoadingSpinner isLoading={isLoading || isDeleting}>
            {task.processName ?
                <div className="uk-grid">
                    <h2 className="uk-width-5-6">{task.processName}</h2>
                    <h2 className="uk-width-1-6 uk-text-right">
                        <a onClick={handleDeleteProcess} className="uk-icon-button uk-button-danger" uk-icon="icon: trash" />
                    </h2>
                </div> : <></>}
            <h3 className="uk-card-title">{task.name}</h3>
            {
                formData
                    ? <DynamicForm task={task}
                                   form={formData}
                                   readonly={false}
                                   data={undefined}
                                   onComplete={handleCompletion}/>
                    : <></>
            }
        </LoadingSpinner>
    </div>
}