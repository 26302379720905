import AsyncSelect from 'react-select/async';
import {useState} from "react";
import {FormInputProps} from "../form-input";
import {Resource, ResourceRef} from "../../../api/resource/model";
import {FormFieldProperties, isReadOnly} from "../../../api/form/model";
import useCustomFetch from "../../../react-query/CustomFetch";
import {useGetResource} from "../../../api/resource/api";

interface ResourceSearchProps extends FormInputProps {

}

function toQuery(props: FormFieldProperties, input: string): string {
    const query = props.searchQuery;
    if (!query) {
        return ""
    }
    if (query.indexOf("$input") !== -1) {
        return query.replaceAll("$input", input)
    }
    return `${query}${input}`
}


interface ResourceOption {
    label: string,
    value: Resource
}


function toResourceRef(resourceOption: ResourceOption | null): ResourceRef | null {
    if (resourceOption === null) {
        return null
    }
    return {id: resourceOption.value.id, kind: resourceOption.value.kind}
}


export function ResourceSearch({
                                   field,
                                   onChange,
                                   readonly
                               }: ResourceSearchProps) {
    const customFetch = useCustomFetch();

    const value = field.value as Partial<ResourceRef>
    const [selected, setSelected] = useState<ResourceOption | null>(null)


    const toResourceOption = (r: Resource): ResourceOption => {
        if (field.properties.labelPath) {
            const parts = field.properties.labelPath.split(".")
            let current = r as any;
            for (const part of parts) {
                if (Object.prototype.hasOwnProperty.call(current, part)) {
                    current = current[part];
                } else {
                    return {label: `${r.kind}/${r.id}`, value: r}
                }
            }
            return {label: `${current}`, value: r}
        }
        return {label: `${r.kind}/${r.id}`, value: r};
    }

    useGetResource(value?.kind ?? "", value?.id ?? "", {
        enabled: value?.kind !== undefined && value?.id !== undefined,
        onSuccess: (res: Resource) => {
            setSelected(toResourceOption(res))
        }
    })

    const loadOptions = (
        inputValue: string
    ): Promise<ResourceOption[]> => customFetch(`/api/${field.properties.kind}?${toQuery(field.properties, inputValue)}`).then((res) => res as Resource[]).then((res) => res.map(toResourceOption))


    const onSelect = (element: ResourceOption | null) => {
        setSelected(element);
        onChange(field, toResourceRef(element))
    }

    return <>
        {readonly || isReadOnly(field) ? <p>
            {selected == null ? "-" : selected.label}
        </p> : <AsyncSelect cacheOptions
                           defaultOptions
                           isClearable
                           isDisabled={readonly}
                           loadOptions={loadOptions}
                           value={selected as any}
                           onChange={onSelect}/>
        }
    </>
}