import './process-type-item.scss'
import {ProcessType} from "../../api/process-type/model";
import {useStartProcess} from "../../api/process/api";
import {LoadingSpinner} from "../../components/loading-spinner/loading-spinner";
import {MutationPath} from "../../api/mutation-path";

interface ProcessTypeItemProps {
    processType: ProcessType
}

export default function ProcessTypeItem({
    processType
}: ProcessTypeItemProps) {

    const {mutate: startProcess, isLoading} = useStartProcess()
    const handleStartProcess = () => {
        startProcess({
            path: MutationPath.StartProcess,
            body: {
                identifier: processType.identifier
            }
        })
    }


    return <div className="process-type-item">
        <LoadingSpinner isLoading={isLoading}>
        <span className="name">{processType.name}</span>
        <button type="button" onClick={handleStartProcess} className="start-button uk-button">Starten</button>
        </LoadingSpinner>
    </div>
}