import {FormField} from "../../api/form/model";
import {TextBox} from "./text-box/text-box";
import CompleteButton from "./complete-button/complete-button";
import {ResourceSearch} from "./resource-search/resource-search";
import SaveBlob from "./save-blob/save-blob";
import {Task} from "../../api/task/model";
import DateTime from "./date-time/date-time";


export interface FormInputProps {
    task: Task
    field: FormField
    readonly: boolean
    onChange: (field: FormField, value: any) => void
}

function selectInputElement({
    task,
    field,
    readonly,
    onChange
}: FormInputProps) {
    switch (field.properties.inputType) {
        case 'textbox':
            return <TextBox task={task} field={field} readonly={readonly} onChange={onChange}/>
        case 'completeButton':
            return <CompleteButton task={task} field={field} readonly={readonly} onChange={onChange} />
        case 'resourceSearch':
            return <ResourceSearch task={task} field={field} readonly={readonly} onChange={onChange} />
        case 'saveBlob':
            return <SaveBlob task={task} field={field} readonly={readonly} onChange={onChange} />
        case 'dateTime':
            return <DateTime task={task} field={field} readonly={readonly} onChange={onChange} />
    }
    return <></>
}

function DefaultFormInput(props: FormInputProps) {
    const {field} = props;
    const id = `form-input-${props.task.identifier}-${props.field.identifier}`
    return <div className="dynamic-form-input">
        <label htmlFor={id} className="uk-form-label">{field.label}</label>
        <div id={id} className="uk-form-controls">
            {selectInputElement(props)}
        </div>
    </div>
}

export default function FormInput(props: FormInputProps) {
    return <>
        {
            props.field.properties.inputType === 'completeButton' || props.field.properties.inputType === 'saveBlob'
                ? selectInputElement(props)
                : <DefaultFormInput
                    task={props.task}
                    field={props.field}
                    readonly={props.readonly}
                    onChange={props.onChange}/>
        }
    </>
}