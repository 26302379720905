import {useState} from "react";
import {FormInputProps} from "../form-input";
import {valueToString} from "../input-utils";
import {isReadOnly} from "../../../api/form/model";

interface TextBoxProps extends FormInputProps {

}

export function TextBox({
    field,
    readonly,
    onChange
}: TextBoxProps) {

    const [value, setValue] = useState(valueToString(field.value))

    const updateValue = (newValue: string) => {
        setValue(newValue);
        onChange(field, newValue)
    }

    const id = `textbox-${field.identifier}`

    return <>{
        (readonly || isReadOnly(field))
        ? <label htmlFor={id}>{value}</label>
        : <input id={id} className="uk-input"
                 readOnly={readonly || isReadOnly(field)}
                 value={`${value}`}
                 onChange={(e) => updateValue(e.target.value)}/>
    }</>;
}