import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {QueryClient, QueryClientProvider} from "react-query";
import {AuthProvider, useAuth} from "react-oidc-context";
import {fetchRuntimeConfig, RuntimeConfig} from "./runtime-config/RuntimeConfig";
import App from "./App";
import {LoadingSpinner} from "./components/loading-spinner/loading-spinner";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
        },
    },
});

function AppWithUser() {
    const {isLoading, isAuthenticated, signinRedirect} = useAuth()
    if(!isLoading && !isAuthenticated) {
        signinRedirect();
    }

    return <LoadingSpinner isLoading={isLoading}>
        <QueryClientProvider client={queryClient}>
            <App/>
        </QueryClientProvider>
    </LoadingSpinner>
}


function AppWithRuntimeConfig({runtimeConfig}: { runtimeConfig: RuntimeConfig }) {
    return <AuthProvider
            authority={runtimeConfig.oauthAuthority}
            client_id={runtimeConfig.clientId}
            redirect_uri={`${window.location.protocol}//${window.location.host}/login`}
            automaticSilentRenew
            onSigninCallback={() => window.location.assign(`${window.location.protocol}//${window.location.host}`)}>
            <AppWithUser />
        </AuthProvider>
}


function AppWithAuthentication() {
    const [runtimeConfig, setRuntimeConfig] = useState<RuntimeConfig>()
    useEffect(() => {
        fetchRuntimeConfig().then(res => setRuntimeConfig(res))
    }, [])

    return <>
        <LoadingSpinner isLoading={runtimeConfig === undefined}>
            {runtimeConfig ?
                <AppWithRuntimeConfig runtimeConfig={runtimeConfig} /> : <></>}
        </LoadingSpinner>

    </>
}

ReactDOM.render(
    <React.StrictMode>
        <AppWithAuthentication/>
    </React.StrictMode> as any,
    document.getElementById('root')
);
