import {FormInputProps} from "../form-input";
import {isReadOnly} from "../../../api/form/model";

interface DateTimeProps extends FormInputProps {
}

export default function DateTime({
                                     field,
                                     readonly
                                 }: DateTimeProps) {


    return <>{
        isReadOnly(field) || readonly
            ? <>
                {new Date(field.value).toLocaleString(
                    [],
                    {
                        day: '2-digit',
                        month: '2-digit',
                        minute: '2-digit',
                        hour: '2-digit',
                        year: 'numeric'
                    })
                }
            </>
            : <></>
    }</>
}