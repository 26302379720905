import {useEffect, useState} from 'react';

export interface RuntimeConfig {
    oauthAuthority: string;
    clientId: string;
    dashboardUrl: string;
}


export async function fetchRuntimeConfig(): Promise<RuntimeConfig> {
    const response = await fetch('/config/runtime.json');
    return (await response.json()) as RuntimeConfig;
}

export default function useRuntimeConfig(): [RuntimeConfig | undefined, boolean, Error | undefined] {
    const [runtimeConfig, setRuntimeConfig] = useState<RuntimeConfig>();
    const [fetchError, setFetchError] = useState<Error>();
    useEffect(() => {
        const fetchConfig = async () => {
            const response = await fetch('/config/runtime.json');
            return (await response.json()) as RuntimeConfig;
        };
        fetchConfig().then(
            (res) => setRuntimeConfig(res),
            (err) => setFetchError(new Error(`Error fetching the runtime configuration: ${err}`))
        );
    }, []);
    return [runtimeConfig, runtimeConfig === undefined, fetchError];
}

