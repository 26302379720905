import {MutationKey, useMutation, UseMutationOptions, UseMutationResult} from 'react-query';
import useCustomFetch, {FetchError} from "../react-query/CustomFetch";
import {MutationPath} from "./mutation-path";


export type SubmitMethod = 'PATCH' | 'POST';

export type FetchMutationVariables<RequestBodyType> = {
    body: RequestBodyType;
    path: MutationPath;
    requestMethod: SubmitMethod;
};

interface DeleteVariables {
    path: string | MutationPath;
}


export type PatchMutationVariables<RequestBodyType> = Omit<FetchMutationVariables<RequestBodyType>, 'requestMethod'>;
export type PostMutationVariables<RequestBodyType> = Omit<FetchMutationVariables<RequestBodyType>, 'requestMethod'>;
export type PutMutationVariables<RequestBodyType> = Omit<FetchMutationVariables<RequestBodyType>, 'requestMethod'>;

export function useDeleteMutation(
    mutationKey?: MutationKey,
    options?: UseMutationOptions<unknown, FetchError, DeleteVariables>
): UseMutationResult<unknown, FetchError, DeleteVariables> {
    const customFetch = useCustomFetch();
    return useMutation(
        async ({path}: DeleteVariables) =>
            customFetch(path.toString(), {
                method: 'DELETE',
            }),
        {
            mutationKey,
            ...options,
            onError: async (error, variables, context) => {
                const onError = options?.onError;
                if (onError) {
                    await onError(error, variables, context);
                }
            },
        }
    );
}


export function usePostMutation<RequestBodyType, ResponseBodyType>(
    mutationKey: string,
    options?: UseMutationOptions<ResponseBodyType, FetchError, PostMutationVariables<RequestBodyType>>
): UseMutationResult<ResponseBodyType, FetchError, PostMutationVariables<RequestBodyType>> {
    const customFetch = useCustomFetch();
    return useMutation(
        mutationKey,
        async ({body, path}: PostMutationVariables<RequestBodyType>) => {
            const response = await customFetch(path.toString(), {
                method: 'POST',
                body: (body ? JSON.stringify(body) : undefined) as any,
            });
            return response as ResponseBodyType;
        },
        {
            ...options,
            onError: async (error, variables, context) => {
                const onError = options?.onError;
                if (onError) {
                    await onError(error, variables, context);
                }
            },
        }
    );
}