import {MouseEvent} from "react";
import {FormInputProps} from "../form-input";
import useCustomFetch from "../../../react-query/CustomFetch";
import {BlobReference} from "../../../api/form/model";

interface SaveBlobProps extends FormInputProps {

}

const contentDispositionFileNamePattern = /filename=("([^"]+)"|([^;])+)/

export default function SaveBlob({
                                     task,
                                     field
                                 }: SaveBlobProps) {
    const {properties} = field
    const customFetch = useCustomFetch();
    const blobReference = field.value as BlobReference

    const getFileName = (headers: any) => {
        if (properties.fileName) {
            return properties.fileName
        }
        const contentDisposition = headers.get('content-disposition');
        if (contentDisposition) {
            const contentDispositionString = `${contentDisposition}`
            const matches = contentDispositionString.match(contentDispositionFileNamePattern)
            if (matches) {
                if (matches[2]) {
                    return matches[2]
                }
                if (matches[1]) {
                    return matches[1]
                }
            }
        }
        return "download"
    }

    const downloadFile = (e: MouseEvent) => {
        const doLoad = async () => {
            const res = await customFetch(`/api/task/${task.identifier}/form/blob/${blobReference.blobIdentifier}`);

            const responseBlob = await res.blob()
            const blob = new Blob([responseBlob], {type: 'application/x-octet-stream'})
            const downloadAnchor = document.createElement(
                "a"
            )
            downloadAnchor.style.display = 'none';
            downloadAnchor.href = (window as any).URL.createObjectURL(blob)
            downloadAnchor.download = getFileName(res.headers);
            downloadAnchor.click()
        }
        doLoad()
        e.preventDefault()
    }

    return <>
        <button type="button" className="uk-button" onClick={downloadFile}>
            <span uk-icon="download"/>&nbsp;{field.label}
        </button>
    </>
}