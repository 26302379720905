import './App.scss'
import liseLogo from '../liseBlatt.png'
import {useGetTasks} from "./api/task/api";
import TaskItem from "./task/item/task-item";
import {LoadingSpinner} from "./components/loading-spinner/loading-spinner";
import {useGetProcessTypes} from "./api/process-type/api";
import ProcessTypeItem from "./process-type/item/process-type-item";

function App() {
    const {data: tasks, isLoading} = useGetTasks();
    const {data: processTypes, isLoading: isLoadingProcessTypes} = useGetProcessTypes();
    return <>
        <div className="uk-grid">
            <div className="uk-visible@m uk-width-1-10@m uk-width-1-5@l" />
            <div className="uk-width-1-1 uk-width-8-10@m uk-width-3-5@l">
                <div className="header">
                    <img src={liseLogo}/>
                    <h1>lise Cockpit</h1>
                </div>
                <h2>Neuen Prozess starten</h2>
                <div className="uk-card uk-card-default uk-card-body">
                    <LoadingSpinner isLoading={isLoadingProcessTypes}>
                        <div>
                            {
                                processTypes && processTypes.map((processType) => <ProcessTypeItem
                                    key={processType.identifier}
                                    processType={processType}/>
                                )
                            }
                        </div>
                    </LoadingSpinner>
                </div>
                <h2>Meine Prozess-Schritte</h2>
                <LoadingSpinner isLoading={isLoading}>
                    {tasks
                        ? tasks.map(t =>
                            <div key={t.identifier} className="uk-card">
                                <TaskItem task={t}/>
                            </div>
                        ) :
                        <></>
                    }
                </LoadingSpinner>
            </div>
        </div>
    </>
}

export default App;