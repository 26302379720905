import {UseQueryOptions, useQuery, UseQueryResult} from 'react-query';
import useCustomFetch, { FetchError } from './CustomFetch';
import ServerStateKeysEnum from './ServerStateKeysEnum';

type ServerStateQueryKey = [ServerStateKeysEnum, ...Array<unknown>] | ServerStateKeysEnum;

export function useGetQuery<ResponseBodyType>(
    queryKey: ServerStateQueryKey,
    path: string,
    options?: UseQueryOptions<ResponseBodyType, FetchError>
): UseQueryResult<ResponseBodyType, FetchError> {
    const customFetch = useCustomFetch();

    return useQuery<ResponseBodyType, FetchError>(
        queryKey,
        async () => {
            const response = await customFetch(path);
            return (await response) as ResponseBodyType;
        },
        {
            ...options,
            onError: (error) => {
                const onError = options?.onError;
                if (onError) {
                    onError(error);
                }
            },
        }
    );
}
