import {UseQueryOptions} from "react-query";
import {ProcessType} from "./model";
import {FetchError} from "../../react-query/CustomFetch";
import {useGetQuery} from "../../react-query/GetQuery";
import ServerStateKeysEnum from "../../react-query/ServerStateKeysEnum";


export function useGetProcessTypes(
    options?: UseQueryOptions<ProcessType[], FetchError>
) {
    return useGetQuery<ProcessType[]>(
        [ServerStateKeysEnum.ProcessTypes],
        `/api/process-type`,
        options
    )
}