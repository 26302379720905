

export class MutationPath {

    private readonly path: string;

    static readonly StartProcess = new MutationPath(`/api/process`);

    private constructor(path: string) {
        this.path = path;
    }


    public static CompleteTask(taskId: string): MutationPath {
        return new MutationPath(`/api/task/${taskId}/completion`)
    }

    public static DeleteProcess(processId: string): MutationPath {
        return new MutationPath(`/api/process/${processId}`)
    }

    public toString() {
        return this.path;
    }

}