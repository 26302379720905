
export type FormFieldInputType = 'textbox' | 'completeButton' | 'resourceSearch' | 'saveBlob' | 'dateTime';
export type FormFieldType = 'string' |  'boolean';
export type CompletionFlavor = 'success' | 'primary' | 'warning' | 'danger';
export type StringlyBoolean = boolean | 'true' | 'false'

export interface FormFieldProperties {
    inputType?: FormFieldInputType;
    completionFlavor?: CompletionFlavor;
    readonly?: StringlyBoolean;
    searchQuery?: string;
    labelPath?: string;
    fileName?: string
    kind: string;
}

export interface BlobReference {
    blobIdentifier: string
}

export interface FormField {
    identifier: string;
    label: string;
    type: FormFieldType;
    properties: FormFieldProperties;
    value?: any | BlobReference
}

export interface Form {
    fields: FormField[];
}

export function isReadOnly(f: FormField) {
    const readOnly = f.properties.readonly;
    return readOnly === true || readOnly === 'true';
}